import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import shared from '@/shared.js';
import tooltip from "@/directives/tooltip.js";
import "@/assets/tooltip.css";

import { createBootstrap } from 'bootstrap-vue-next'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '@/assets/boostrap-custom.scss';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
}from 'chart.js'

// Create the app and apply the mixin
createApp(App)
    .use(store)
    .use(router)
    .use(createBootstrap())
    .directive("tooltip", tooltip)
    .mixin(shared)
    .mount('#app')


window.addEventListener('resize', () => {
    store.dispatch("resize")
})

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
