<template>
  <router-view />
</template>

<script>
  import * as faceapi from '@vladmandic/face-api'
  import initImageSrc from "@/assets/init.jpg"
  import Settings from "@/js/settings.js"
  import Api from '@/js/api.js'
  import { getCookieValue } from '@/js/auth'
  export default {
    mounted() {
      if(localStorage && localStorage.getItem("iam.access-token")){
        Api.tokenValid().then(ok=>{
          if(ok){
            this.tokenRefresher = window.setInterval(() => {
              this.$store.dispatch('refreshTokenIfNeeded');
            }, 5 * 60 * 1000);
          }
          else{
            console.log("clear access token")
            localStorage.removeItem("iam.access-token")
            this.$store.dispatch('refreshTokenIfNeeded');
          }
        })
      }
      if(localStorage.getItem(Settings.INIT_FACE_API) == "true"){
        this.initFaceApi()
      }

      // fullscreen change
      document.addEventListener("fullscreenchange", () => {
          this.$store.dispatch("fullscreenChange")
      });

      // highlight dev environment
      const cookieval = getCookieValue("highlight_environment_identifier")
      let color = undefined
      if (cookieval == "LOCAL") {
        color = "lightgreen";
      } else if (cookieval == "STAGING") {
        color = "orange"
      }
      if (color !== undefined) {
        const css = ".highlight-environment { background-color: " + color + " !important; }";
        var styleSheet = document.createElement("style")
        styleSheet.textContent = css
        document.head.appendChild(styleSheet)
      }
    },
    methods: {
      async initFaceApi() {
          let modelPath = "model"
          await faceapi.nets.tinyFaceDetector.load(modelPath);
          await faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelPath)
          await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath)
          const initImage = await faceapi.fetchImage(initImageSrc)
          await faceapi.detectAllFaces(initImage, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks(true)
              .withFaceDescriptors();
          console.log("Init done")
          this.$store.commit("faceApiIsReady")
      }
    }
  }
</script>

<style lang="scss">

  @import '@/assets/global.scss';
  
</style>
