import { createStore } from 'vuex'
import { getCookieValue, decodeJWT } from '@/js/auth'
import Api from '@/js/api'
import * as faceapi from '@vladmandic/face-api'

let token = localStorage.getItem("iam.access-token")
let token_decoded = decodeJWT(token)

if (token && !token_decoded) {
  console.log("clear access token")
  localStorage.removeItem("iam.access-token")
  token = undefined
  token_decoded = undefined
}

let identity = undefined
if (token_decoded) {
  identity = JSON.parse(localStorage.getItem("iam.identity"))
}

let userscopes_cookie = getCookieValue('userscopes')
if (userscopes_cookie)
  userscopes_cookie = atob(userscopes_cookie).split(',')

let editHash_cookie = getCookieValue('edit_uuid')
if (editHash_cookie){
  editHash_cookie = JSON.parse(atob(editHash_cookie))
  let now_seconds = Date.now() / 1000
  if (editHash_cookie['created'] < (now_seconds - 60*60*24)){
    editHash_cookie = undefined
  }
}

let username_cookie = getCookieValue('username')
if (username_cookie)
  username_cookie = atob(username_cookie)

function handleTokenResponse(data) {
  const _atoken = data["token"]
  const _rtoken = data["refresh_token"]
  let _decoded_atoken = decodeJWT(_atoken)
  if (_decoded_atoken) {
    localStorage.setItem("iam.access-token", _atoken)
    if(_rtoken)
      localStorage.setItem("iam.refresh-token", _rtoken)
    
    let identity = null
    if ("identity" in data) {
      identity = data["identity"]
      localStorage.setItem("iam.identity", JSON.stringify(identity))
    }
    return {
      "decoded_access_token": _decoded_atoken,
      "identity_information": identity
    }
  }

  return undefined
}

export default createStore({
  state: {
    scopes: token_decoded ? token_decoded["scopes"] : userscopes_cookie || [],
    username: token_decoded ? token_decoded["sub"] : username_cookie,
    displayname: identity ? identity["displayname"] : token_decoded ? token_decoded["sub"] : username_cookie,
    isLoggedIn: token !== undefined && token !== null,
    isMobile: window.innerWidth <= window.innerHeight,
    isFullscreen: document.fullscreenElement != null,
    editHash: editHash_cookie,
    faceapi: faceapi,
    faceapi_isReady: false,
  },
  getters: {
  },
  mutations: {
    loginSuccess(state, data) {
      const _decoded = data["decoded_access_token"];
      const identity = data["identity_information"];

      state.scopes = _decoded["scopes"];
      state.username = _decoded["sub"];
      state.displayname = (identity != null) ? identity["displayname"] : _decoded["sub"];
      state.isLoggedIn = true;
    },
    loginFailure(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.displayname = username_cookie;
      state.isLoggedIn = false;
    },
    logout(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.displayname = username_cookie;
      state.isLoggedIn = false;
    },
    switchIsMobile(state) {
      state.isMobile = !state.isMobile
    },
    switchIsFullscreen(state){
      state.isFullscreen = !state.isFullscreen
    },
    faceApiIsReady(state){
      state.faceapi_isReady = true
    }
  },
  actions: {
    login({ commit }, data) {
      let login_request = null;
      if (data.mode == "credentials") {
        login_request = Api.login(data.username, data.password);
        localStorage.removeItem("iam.refreshmode.certificate")
      }
      else if (data.mode == "certificate") {
        login_request = Api.certificate_login();
        localStorage.setItem("iam.refreshmode.certificate", "true")
      }
      else if (data.mode == "onetimetoken") {
        const token = data.token
        if (token === undefined || token == "") {
          return Promise.reject("No One-Time-Token provided!");
        }
        login_request = Api.loginByOneTimeToken(token);
        // we want to use the normal token refresh logic from now on, even if we were authenticated via certificate
        localStorage.removeItem("iam.refreshmode.certificate")
      }
      else {
        commit('loginFailure');
        return Promise.reject("no auth mode defined");
      }

      return login_request.then(
          _tokens => {
            let _decoded = handleTokenResponse(_tokens)
            if (_decoded) {
              commit('loginSuccess', _decoded);
              return Promise.resolve();
            }
            else {
              commit('loginFailure');
              return Promise.reject();
            }
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
    },
    logout({ commit }, data) {
      let everywhere = "everywhere" in data ? data["everywhere"] : false;
      Api.logout(everywhere);
      localStorage.removeItem("iam.access-token")
      localStorage.removeItem("iam.refresh-token")
      localStorage.removeItem("iam.refreshmode.certificate")
      localStorage.removeItem("iam.identity")
      commit('logout');
    },
    refreshTokenIfNeeded({ commit }) {
      let access_token = localStorage.getItem("iam.access-token")
      let access_token_decoded = decodeJWT(access_token, 10*60)
      if (access_token_decoded) {
        return
      }
      let refresh_token = localStorage.getItem("iam.refresh-token")
      let refresh_token_decoded = decodeJWT(refresh_token)
      let action = null
      if (!refresh_token_decoded) {
        if (localStorage.getItem("iam.refreshmode.certificate") == "true") {
          console.log("refresh with certificate")
          action = Api.certificate_login()
        } else {
          console.log("no valid refresh token found - cannot do anything")
          localStorage.removeItem("iam.access-token")
          localStorage.removeItem("iam.refresh-token")
          localStorage.removeItem("iam.identity")
          commit('logout');
          return
        }
      } else {
        action = Api.refreshToken()
      }

      if (action == null) {
        console.error("refresh action is null - this should not happed")
      } else {
        action.then(_tokens => {
            let _decoded = handleTokenResponse(_tokens)
            commit('loginSuccess', _decoded);
          },
          error => {
            console.error("refresh failed - clear tokens")
            localStorage.removeItem("iam.access-token")
            localStorage.removeItem("iam.identity")
            localStorage.removeItem("iam.refresh-token")
            localStorage.removeItem("iam.refreshmode.certificate")
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
      }
    },

    resize({ commit }) {
      if (this.state.isMobile != (window.innerWidth <= window.innerHeight))
        commit('switchIsMobile');
    },
    fullscreenChange({commit}){
      if (this.state.isFullscreen != (document.fullscreenElement != null))
        commit('switchIsFullscreen');
    }
  },
  modules: {
  }
})


