


const cookie_regex = (name)=> new RegExp(`(^| )${name}=([^;]+)`)
export function getCookieValue(name) {
    const match = document.cookie.match(cookie_regex(name))
    if (match) 
        return match[2]
}
export function eraseCookie(name) {   
    const match = document.cookie.match(cookie_regex(name))
    if (match)
        document.cookie = name+'=; Max-Age=-99999999;';  
}
export function decodeJWT(jwt, seconds_grace=0){
    if (jwt) {
        try {
            let tokens = jwt.split(".");
            let raw_payload = JSON.parse(atob(tokens[1]))
            let expiry = raw_payload["exp"]
            let now_seconds = Date.now() / 1000
            if (now_seconds < expiry - seconds_grace){
                return raw_payload
            }
        } catch (e) {
            // eslint-disable-next-line
            console.warn("token malformed.", e)
        }
    }
}