

import store from "@/store"
import {canEditId} from "@/shared"
function authHeader(useRefreshToken = false) {
    let authHeader = {}
    const tokenKey = useRefreshToken ? "iam.refresh-token" : "iam.access-token"
    if (localStorage.getItem(tokenKey)) {
        authHeader["Authorization"] = "Bearer " + localStorage.getItem(tokenKey)
    }
    return authHeader
}
function jsonResponse(response) {
    if(response.ok){
        return response.json();
    }
    else if (response.status == 404){
        return Promise.resolve()
    }
    else{
        throw new Error(`HTTP error: ${response.status}`);
    }
}
function handleError(error) {
    // eslint-disable-next-line
    console.error('Error:', error);
    throw error;
}
function fetchPlus(url, options, retries=2){
    return fetch(url, options)
      .then((response) => {
        if (response.ok || response.status == 404 || retries === 0) {
          return response
        }
        return fetchPlus(url, options, retries - 1)
      })
}
function makePOST(url, data, useRefreshToken = false) {
    let initObject = {
        method: 'POST',
        headers: { ...authHeader(useRefreshToken), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    if (AbortSignal.timeout) {
        initObject["signal"] = AbortSignal.timeout(5000)
    }
    return fetch(buildFullUrl(url), initObject)
}

function makeCustomPOST(url, one_time_token) { // will be removed again soon, hence no deeper refactoring
    return fetch(buildFullUrl(url), {
        method: 'POST',
        headers: { "Authorization": "Bearer " + one_time_token, 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
    })
}
function makeGET(url) {
    return fetchPlus(buildFullUrl(url), {headers: authHeader()})
}

function buildFullUrl(relative) {
    return "/api/v1" + relative; // preparation for later - so it can point to dev API / localhost
}

class Api {
    saveGame(gameid, update) {
        if (!canEditId(gameid))
            return new Promise(()=> false);

        let synched = true;
        if ('synched' in update){
            synched = update['synched']
            //do not send to server
            delete update['synched']
        }
        return makePOST("/game", { id: gameid, content: update })
            .then(response=>{
                if(response.ok){
                    if(synched === false)
                        console.info('Synched game');
                    return true
                }
                else{
                    throw new Error(`HTTP error: ${response.status}`);
                }
            })
            .catch(error=> {
                //Otherwise many logs
                if(synched === true) 
                    console.error('Error:', error);
                return false
            });
    }

    getEditUUID(tid) {
        if (store.state.scopes.includes('api.tournament.w'))
            return makeGET(`/edituuid/${tid}`)
                .then(jsonResponse)
                .catch(handleError);
        else
            return Promise.resolve()
    }

    loadTournament(tid) {
        return makeGET(`/tournament/${tid}`)
            .then(jsonResponse)
            .catch(handleError);
    }

    loadTournamentLastTwoDays() {
        return makeGET(`/tournament/last_two_days`)
            .then(jsonResponse)
            .catch(handleError);
    }

    saveTournament(tid, content) {
        return makePOST("/tournament", { id: tid, content })
            .catch(handleError);
    }
    loadGame(gameid) {
        if (!store.state.scopes.includes("api.game.br"))
            return Promise.resolve(undefined)
        return makeGET(`/game/${gameid}`)
            .then(jsonResponse)
            .catch(handleError);
    }
    loadGamesLastTwoWeeks() {
        if (!store.state.scopes.includes("api.game.br"))
            return Promise.resolve(new Map())
        return makeGET(`/game/last_two_weeks`)
            .then(jsonResponse)
            .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
            .catch(handleError);
    }
    loadGamesOngoing() {
        if (!store.state.scopes.includes("api.game.br")){
            return Promise.resolve(new Map())
        }
        return makeGET("/game/ongoing")
            .then(jsonResponse)
            .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
            .catch(handleError);
    }
    loadGamesByTid(tid) {
        if (store.state.scopes.includes("api.game.br") || (tid && store.state.editHash && tid.startsWith(store.state.editHash.id)))
            return makeGET(`/game/load_by_tid/${tid}`)
                .then(jsonResponse)
                .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
                .catch(handleError);
        else
            return Promise.resolve(new Map())
    }
    loadGamesByPlayer() {
        if (store.state.scopes.includes("api.game.br"))
            return makeGET(`/game/load_by_player`)
                .then(jsonResponse)
                .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
                .catch(handleError);
        else
            return Promise.resolve(new Map())
    }
    loadGamesByPlayersLastN(playernames) {
        if (store.state.scopes.includes("api.game.br"))
            return makeGET(`/game/load_by_players_lastn?` + playernames.map(p => `player=${p}`).join('&'))
                .then(jsonResponse)
                .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
                .catch(handleError);
        else
            return Promise.resolve(new Map())
    }
    loadGamesBetweenPlayersLastN(playernames) {
        if (store.state.scopes.includes("api.game.br"))
            return makeGET(`/game/load_between_players_lastn?` + playernames.map(p => `player=${p}`).join('&'))
                .then(jsonResponse)
                .then(data => new Map(data.map(game => [game.id, {...JSON.parse(game.content),last_changed:game.last_changed}])))
                .catch(handleError);
        else
            return Promise.resolve(new Map())
    }
    loadPlayers() {
        if (!store.state.scopes.includes("api.player.br"))
            return Promise.resolve([])

        return makeGET('/player/load_all')
            .then(jsonResponse)
            .catch(handleError);
    }
    loadPlayer() {
        return makeGET('/player')
            .then(jsonResponse)
            .catch(handleError);
    }
    
    tokenValid() {
        return makeGET("/user/token/valid")
            .then(response=>response.ok)
            .catch(false);
    }
    
    login(username, password) {
        return makePOST("/user/login", { username, password })
            .then(jsonResponse)
            .catch(handleError);
    }
    getCloneToken() {
        return makeGET("/user/session/clonetoken")
            .then(jsonResponse)
            .catch(handleError);
    }
    loginByOneTimeToken(oneTimeToken) {
        return makeCustomPOST("/user/login/clonetoken", oneTimeToken)
            .then(jsonResponse)
            .catch(handleError);
    }
    certificate_login() {
        // FIXME: we set here "use refresh token" but it just results in an empty Bearer header (so the certificate is actually used)
        return makePOST("/user/login/certificate", {}, true)
            .then(jsonResponse)
            .catch(handleError);
    }
    logout(everywhere=false) {
        const urlAdd = everywhere ? "/everywhere" : ""
        return makePOST("/user/logout" + urlAdd, {})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            })
            .catch(handleError);
    }
    refreshToken() {
        return makePOST("/user/token/refresh", {}, true)
            .then(jsonResponse)
            .catch(handleError);
    }
}

export default new Api();

