import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/account/TokenLogin',
    name: 'tokenlogin',
    component: () => import('@/views/TokenLoginView.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/AccountView.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    home_button: true,
    component: () => import('@/views/StatisticsView.vue'),
  },
  {
    path: '/groupphase',
    name: 'groupphase',
    component: () => import('@/views/GroupphaseView.vue'),
    props: route => ({ 
      tname: route.query.tname,
    }),
    meta: { 
      scopes: ["api.tournament.r"]
    }
  },
  {
    path: '/eliminationphase',
    name: 'eliminationphase',
    component: () => import('@/views/EliminationView.vue'),
    props: route => ({ 
      tname: route.query.tname,
    }),
    meta: { 
      scopes: ["api.tournament.r"],
      allowWithHash: true
    }
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: () => import('@/views/TournamentView.vue'),
    meta: { 
      scopes: ["api.tournament.r"]
    }
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@/views/GameView.vue'),
    props: route => ({ 
      quickgame: route.query.quickgame,
      gameid: route.query.gameid,
    })
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/ResultView.vue'),
    props: route => ({ 
      gameid: route.query.gameid,
    })
  },
  {
    path: '/games/ongoing',
    name: 'ongoinggames',
    home_button: true,
    component: () => import('@/views/OngoingGamesView.vue'),
    meta: {
      scopes: ["api.game.br"]
     }
  },
  {
    path: '/watchgame',
    name: 'watchgame',
    component: () => import('@/views/WatchGameView.vue'),
    props: route => ({ 
      gameid: route.query.gameid,
    }),
    meta: { 
      scopes: ["ws.game.updates.s"]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.scopes) {
    if (!to.meta.scopes.some(s=>store.state.scopes.includes(s)) && (!store.state.editHash || !to.meta.allowWithHash)) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
